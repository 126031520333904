import React, { useCallback, useState, useEffect } from "react"
import PropTypes from "prop-types"

import SearchFieldIcon from "../icons/SearchFieldIcon"
import { useStore } from "../../store/useStore"

import styles from "./styles.module.css"
import * as JsSearch from "js-search"

const Search = ({
  name,
  placeholder,
  ariaLabel,
  autoComplete,
  engine,
  dataset,
  booksSearch,
  creatorsSearch,
}) => {
  const { state, dispatch } = useStore()
  const { bookSearchQuery, creatorsSearchQuery } = state

  const setBookSearchResponse = useCallback(
    searchResponse => {
      dispatch({ type: "setBookSearchResponse", payload: searchResponse })
    },
    [dispatch]
  )

  const setCreatorsSearchResponse = useCallback(
    searchResponse => {
      dispatch({ type: "setCreatorsSearchResponse", payload: searchResponse })
    },
    [dispatch]
  )

  const [searchState, setSearchState] = useState({
    isLoading: true,
    search: null,
    isError: false,
    indexByTitle: false,
    indexByCreators: false,
    indexByLastName: false,
    indexByFirstName: false,
    termFrequency: true,
    removeStopWords: false,
    selectedStrategy: "",
    selectedSanitizer: "",
  })

  let [prevSearchState, setPrevSearchState] = useState(null)

  if (searchState !== prevSearchState) {
    if (searchState.search === null) {
      setSearchState({
        indexByTitle: engine.TitleIndex,
        indexByCreators: engine.CreatorsIndex,
        indexByIsbn: engine.IsbnIndex,
        indexByLastName: engine.LastNameIndex,
        indexByFirstName: engine.FirstNameIndex,
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
      })
    }
    setPrevSearchState(searchState)
  }

  useEffect(() => {
    rebuildIndex()
  }, [])

  const rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTitle,
      indexByCreators,
      indexByIsbn,
      indexByLastName,
      indexByFirstName,
    } = searchState

    const dataToSearch = new JsSearch.Search("id")

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex("title")
    }
    // sets the index attribute for the data
    if (indexByCreators) {
      dataToSearch.addIndex("creators")
    }

    if (indexByIsbn) {
      dataToSearch.addIndex("isbn")
    }

    if (indexByLastName) {
      dataToSearch.addIndex("lastName")
    }

    if (indexByFirstName) {
      dataToSearch.addIndex("firstName")
    }

    dataToSearch.addDocuments(dataset) // adds the data to be searched

    setSearchState({ search: dataToSearch, isLoading: false })
  }

  const onSearchChange = e => {
    const { search } = searchState
    const queryResult = search.search(e.target.value)

    if (booksSearch) {
      setBookSearchResponse({
        searchQuery: e.target.value,
        searchResults: queryResult,
      })
    }
    if (creatorsSearch) {
      setCreatorsSearchResponse({
        searchQuery: e.target.value,
        searchResults: queryResult,
      })
    }
  }

  return (
    <div
      className={styles.search}
      style={{
        position: `relative`,
        margin: `0.875rem 24.4205298013245%`,
      }}
    >
      <input
        type="search"
        id="search"
        name={name}
        value={booksSearch ? bookSearchQuery : creatorsSearchQuery}
        aria-label={ariaLabel}
        placeholder={placeholder}
        autoComplete={autoComplete ? "on" : "off"}
        style={{
          width: `100%`,
          height: `3.5rem`,
          padding: `0.875rem`,
          border: `none`,
          background: `rgba(255, 255, 255, 0.75)`,
          borderRadius: 3,
          fontSize: `1.125rem`,
          textAlign: `center`,
        }}
        className={styles.input}
        onChange={onSearchChange}
      />
      <button
        type="submit"
        className={`${styles.button}`}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `3.5rem`,
          height: `3.5rem`,
          padding: 0,
        }}
      >
        <SearchFieldIcon />
      </button>
    </div>
  )
}

Search.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  autoComplete: PropTypes.bool,
}

Search.defaultProps = {
  autoComplete: true,
}

export default Search
