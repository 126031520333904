import React, { useCallback, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageNav from "../components/PageNav"
import Search from "../components/Search"
import CategoryList from "../components/CategoryList"
import BookList from "../components/BookList"
import Button from "../components/Button"
import { useStore } from "../store/useStore"

import * as inlineStyles from "../components/Layout/inlineStyles"
import styles from "./bocker.module.css"

const Bocker = props => {
  const { state, dispatch } = useStore()
  const {
    booksToShow,
    selectedAgeGroups,
    selectedBookCategories,
    bookSearchQuery,
    bookSearchResults,
  } = state

  const addBooksToShow = useCallback(
    () => dispatch({ type: "addBooksToShow" }),
    [dispatch]
  )

  const setAgeGroups = useCallback(
    selectedOption => {
      dispatch({ type: "setAgeGroups", payload: selectedOption })
    },
    [dispatch]
  )

  const setBookCategories = useCallback(
    selectedOption => {
      dispatch({ type: "setBookCategories", payload: selectedOption })
    },
    [dispatch]
  )

  const resetBooksList = useCallback(() => {
    dispatch({ type: "resetBooksList" })
  }, [dispatch])

  const books = props.data.allWordpressWpBooks.edges.map(
    bookItem => bookItem.node
  )

  const ageGroups = props.data.allWordpressWpAgeGroups.edges.map(
    ageGroupItem => ageGroupItem.node
  )

  const bookCategories = props.data.allWordpressWpBookCategories.edges.map(
    bookCategoryItem => bookCategoryItem.node
  )

  const bookSeriesCategories = props.data.allWordpressWpBookSeriesCategories.edges.map(
    bookSeriesCategoryItem => bookSeriesCategoryItem.node
  )

  const combinedBookCategories = [...bookCategories, ...bookSeriesCategories]

  const booksFilteredBySearch = bookSearchQuery
    ? books.filter(b => bookSearchResults.map(r => r.id).includes(b.id))
    : books

  const handleAgeGroupsChange = selectedOptions => {
    setAgeGroups(selectedOptions)
  }

  const handleBookCategoriesChange = selectedOptions => {
    setBookCategories(selectedOptions)
  }

  const filteredBooks = booksFilteredBySearch.filter(
    b =>
      b.ageGroups.find(
        a =>
          !selectedAgeGroups.length ||
          selectedAgeGroups.map(a => a.value).includes(a)
      ) &&
      b.bookCategories.find(
        a =>
          !selectedBookCategories.length ||
          selectedBookCategories.map(a => a.value).includes(a)
      )
  )

  const slicedFilteredBooks = filteredBooks.slice(0, booksToShow)

  return (
    <Layout>
      <SEO title="Böcker" />
      <PageNav>
        <Search
          name="bookQuery"
          placeholder="Sök böcker"
          ariaLabel="Sök böcker"
          autoComplete={false}
          booksSearch
          engine={{
            indexStrategy: "Prefix match",
            searchSanitizer: "Lower Case",
            TitleIndex: true,
            CreatorsIndex: true,
            IsbnIndex: true,
            SearchByTerm: true,
          }}
          dataset={books.map(b => {
            return {
              id: b.id,
              title: b.title,
              isbn: b.acf.isbn,
              creators: b.acf.creators.map(
                c => (c.creator && c.creator.title) || ""
              ),
            }
          })}
        />
        <div className={styles.categories}>
          <CategoryList
            options={ageGroups}
            handleChange={handleAgeGroupsChange}
            value={selectedAgeGroups}
            title="Åldrar"
            placeholder="Välj åldrar"
          />
          <CategoryList
            options={combinedBookCategories}
            handleChange={handleBookCategoriesChange}
            value={selectedBookCategories}
            title="Kategorier"
            placeholder="Välj kategorier"
          />
        </div>
      </PageNav>
      <div className="container" style={inlineStyles.container}>
        {bookSearchQuery ||
        selectedAgeGroups.length ||
        selectedBookCategories.length ? (
          <div className={styles.searchResultTitleContainer}>
            <h1 className={styles.resetTitle}>Sökresultat</h1>
            <div className={styles.resetButtonContainer}>
              <button
                type="button"
                className={styles.resetButton}
                onClick={resetBooksList}
              >
                Återställ sökning
              </button>
            </div>
          </div>
        ) : (
          <h1 className={styles.title}>Böcker</h1>
        )}
        {slicedFilteredBooks.length ? (
          <div className={styles.books}>
            <BookList books={slicedFilteredBooks} />
            <div
              className={`${styles.buttonContainer} ${styles.showMoreContainer}`}
            >
              <Button
                onClick={addBooksToShow}
                disabled={booksToShow >= filteredBooks.length}
              >
                Visa fler böcker
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.books}>
            <div className={styles.noResultsContainer}>
              <p>Inga resultat</p>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Bocker

export const pageQuery = graphql`
  {
    allWordpressWpAgeGroups(sort: { order: DESC, fields: acf___order }) {
      edges {
        node {
          id
          value: wordpress_id
          label: name
          slug
        }
      }
    }
    allWordpressWpBookCategories(
      filter: { wordpress_parent: { eq: 0 } }
      sort: { order: DESC, fields: acf___order }
    ) {
      edges {
        node {
          id
          value: wordpress_id
          label: name
          slug
        }
      }
    }
    allWordpressWpBookSeriesCategories: allWordpressWpBookCategories(
      filter: { wordpress_parent: { gt: 0 } }
      sort: { order: DESC, fields: acf___order }
    ) {
      edges {
        node {
          id
          value: wordpress_id
          label: name
          slug
          wordpressParent: wordpress_parent
        }
      }
    }
    allWordpressWpBooks(
      sort: { order: DESC, fields: acf___editions___release_date }
      limit: 1000
    ) {
      edges {
        node {
          id
          title
          slug
          bookCategories: book_categories
          ageGroups: age_groups
          acf {
            isbn
            creators {
              creator {
                title: post_title
                acf {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            editions {
              releaseDate: release_date
            }
            cover {
              altText: alt_text
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
