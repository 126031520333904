import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"
import * as inlineStyles from "../Layout/inlineStyles"

const PageNav = ({ children }) => (
  <div
    style={{
      background: `rgba(0, 169, 197, 0.2)`,
    }}
  >
    <div
      className={`container ${styles.container}`}
      style={inlineStyles.container}
    >
      <nav>{children}</nav>
    </div>
  </div>
)

PageNav.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageNav
