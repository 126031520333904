import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import styles from "./styles.module.css"
import { customReactSelectStyles } from "./customReactStyles"

const CategoryList = ({ title, placeholder, options, value, handleChange }) => {
  return (
    <div className={styles.categoryList}>
      {title && <h3 className={styles.label}>{`${title}:`}</h3>}
      <Select
        className={styles.select}
        value={value}
        onChange={handleChange}
        options={options}
        isMulti
        placeholder={placeholder}
        noOptionsMessage={() => {return "Inga fler alternativ"}}
        isSearchable={false}
        classNamePrefix="react-select"
        styles={customReactSelectStyles}
      />
    </div>
  )
}

export default CategoryList

CategoryList.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
}
