import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"

const Button = ({ onClick, disabled, children }) => (
  <button className={styles.button} onClick={onClick} disabled={disabled}>
    {children}
  </button>
)

export default Button

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Button.defaultProps = {
  disabled: false,
}
