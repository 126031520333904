import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import sanitizeHtml from "sanitize-html"
import styles from "./styles.module.css"

const getOneRowClassNames = itemCount => {
  if (itemCount >= 6) {
    return null
  }

  return `books-count-${itemCount}`
}

const BookList = ({ books }) => (
  <ul className={`${styles.bookList} ${getOneRowClassNames(books.length)}`}>
    {books.map((bookItem, bookIndex) => (
      <li className={styles.bookListItem} key={bookIndex}>
        <Link to={`/bocker/${bookItem.slug}`}>
          <div className={styles.book}>
            {bookItem.acf && bookItem.acf.cover && (
              <div className={styles.cover}>
                <Img
                  src={bookItem.acf.cover.localFile.childImageSharp.src}
                  fluid={bookItem.acf.cover.localFile.childImageSharp.fluid}
                  alt={bookItem.acf.cover.altText}
                />
                {bookItem.acf.editions &&
                bookItem.acf.editions[0] &&
                bookItem.acf.editions[0].releaseDate &&
                new Date(bookItem.acf.editions[0].releaseDate) > new Date() ? (
                  <div className={styles.comingSoon}>Kommer snart</div>
                ) : null}
              </div>
            )}
            <div className={styles.meta}>
              <h4
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(bookItem.title.replace(/\u2028/g, " ")),
                }}
              />
              {bookItem.acf && bookItem.acf.creators && (
                <div
                  className={`${styles.creators} ${
                    bookItem.acf.creators.length > 1 ? styles.multiple : null
                  }`}
                >
                  {bookItem.acf.creators.map((creatorItem, creatorIndex) => (
                    <span className={styles.name} key={creatorIndex}>
                      {(creatorItem.creator &&
                        creatorItem.creator.acf &&
                        creatorItem.creator.acf.firstName &&
                        creatorItem.creator.acf.lastName &&
                        `${creatorItem.creator.acf.firstName} ${
                          creatorItem.creator.acf.lastName
                        }${
                          creatorIndex !== bookItem.acf.creators.length - 1
                            ? ", "
                            : ""
                        }`) ||
                        ((creatorItem.creator &&
                          creatorItem.creator.title &&
                          creatorItem.creator.title) ||
                          "")}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Link>
      </li>
    ))}
  </ul>
)

export default BookList

BookList.propTypes = {
  books: PropTypes.arrayOf(PropTypes.object).isRequired,
}
