export const customReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: "500",
    backgroundColor: state.isSelected ? "rgba(0, 169, 197, 0.1)" : "#fff",
    ":hover": {
      backgroundColor: "rgba(0, 169, 197, 0.1)",
    },
    ":active": {
      backgroundColor: state.isSelected
        ? "rgba(0, 169, 197, 0.25"
        : "rgba(0, 169, 197, 0.1)",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    borderRadius: "3px",
    border: "none",
    height: "2.625rem",
    boxShadow: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#00a9c5",
    fontStyle: "italic",
    fontWeight: "400",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 0.5rem",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#fff",
    background: "#00a9c5",
    borderRadius: "5px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#fff",
    fontWeight: "500",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(0, 169, 197, 0.2)",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "rgba(0, 169, 197, 0.5)",
    "&:hover": {
      color: "rgba(0, 169, 197, 1)",
    },
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: "rgba(0, 169, 197, 0.5)",
    "&:hover": {
      color: "rgba(0, 169, 197, 1)",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "3px",
    boxShadow:
      "0 0 0 1px rgba(0, 169, 197, 0.2), 0 4px 24px rgba(0, 169, 197, 0.15), 0 4px 8px rgba(0, 169, 197, 0.1)",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: "rgba(0, 169, 197, 0.625)",
    fontWeight: "500",
  }),
}

export default customReactSelectStyles